.c-contact-section {
  color: white;
  padding-top: 4em;
  padding-bottom: 8em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/assets/img/home-contact.jpg');
  a:link, a:visited { color: white; }
  a:hover, a:active, a:focus { color: white; }
}

.lt-ie9 {

  .c-contact-section {
    padding: 1em;
    .c-vcard {
      text-align: center;
    }
    .c-social {
      text-align: center;
    }
  }

}
