.c-pagination {
  text-align: center;
  margin-bottom: 2em;
}
.c-pagination__current {
  color: $cs-a1;
}
.c-pagination__link {
  margin-left: 0.2em;
  margin-right: 0.2em;
  &:link, &:visited { text-decoration: underline; }
  &:hover, &:active, &:focus { text-decoration: none; }
}
