.c-footer-form {
  padding: 0.5em;
  overflow: hidden;
}
.c-footer-form__fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.c-footer-form__group {
  margin-top: 0.5em;
  overflow: hidden;
  margin-bottom: 0.5em;
}
.c-footer-form__control {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.2em;
  border: 1px solid #eee;
  background-color: #eee;
  &:focus {
    outline: none;
    border-color: green;
    background-color: white;
  }
}
.c-footer-form__btn {
  color: $cs-font;
  @include transition(all 0.3s);
  padding: 0.5em 0.8em;
  border: 1px solid $cs-font;
  text-transform: uppercase;
  background-color: transparent;
  &:link, &:visited {
    color: $cs-font;
    border: 1px solid $cs-font;
    background-color: transparent;
  }
  &:hover, &:active, &:focus {
    @include transition(all 0.1s);
    color: white;
    border: 1px solid $cs-a1;
    background-color: $cs-a1;
  }
}
.c-footer-form__has-error {
    clear: both;
    color: red;
    text-align: right;
}

@media screen and (min-width: 769px) {

  .c-footer-form__fieldset {
    float: left;
    width: 48%;
  }

  .c-footer-form__label {
    width: 24%;
    float: left;
    text-align: right;
    padding-top: 0.2em;
    padding-right: 1em;
    display: inline-block;
  }

  .c-footer-form__control {
    width: 76%;
    float: right;
  }

  .c-footer-form__btn {
    float: right;
    margin-top: 0.3em;
  }

}

.lt-ie9 {

  .c-footer-form__fieldset {
    float: left;
    width: 48%;
    &:last-child { float: right; }
  }

  .c-footer-form__label {
    width: 24%;
    float: left;
    text-align: right;
    padding-top: 0.2em;
    padding-right: 1em;
    display: inline-block;
  }

  .c-footer-form__control {
    width: 76%;
    float: right;
  }

  .c-footer-form__btn {
    float: right;
    margin-top: 0.3em;
  }

}
