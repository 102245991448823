@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans-Medium.eot');
  src: url('../fonts/BloggerSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BloggerSans-Medium.woff') format('woff'),
    url('../fonts/BloggerSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans-Bold.eot');
  src: url('../fonts/BloggerSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BloggerSans-Bold.woff') format('woff'),
    url('../fonts/BloggerSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
