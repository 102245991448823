.c-social {
  margin: 0;
  padding: 0.5em 0;
  list-style: none;
  text-align: center;
}
.c-social__item {
  margin-right: 0.4em;
  display: inline-block;
}
.c-social__link {
  color: $cs-a1;
  font-size: 1.3em;
  text-decoration: none;
  &:link, &:visited {
    color: $cs-a1;
    text-decoration: none;
  }
  &:hover, &:active, &:focus {
    color: $cs-a1;
    text-decoration: none;
  }
}

.c-social__item--header {
  @extend .c-social__item;
}

@media screen and (min-width: 769px) {

  .c-social {
    margin: 0;
    padding: 0.5em 0;
    text-align: left;
  }

  .c-social--header {
    display: inline;
    margin: 0 0 0 1em;
  }
  .c-social__item--header {
    margin-left: 0.4em;
  }

}

.lt-ie9 {

  .c-social {
    margin: 0;
    padding: 0.5em 0;
    text-align: left;
  }

  .c-social--header {
    display: inline;
    margin: 0 0 0 1em;
  }
  .c-social__item--header {
    margin-left: 0.4em;
  }

}
