// NAVIGATION
.c-nav {
  margin-top: 0.5em;
  @include clearfix;
}
.c-nav__list {
  margin: 0;
  padding: 0;
  clear: right;
  display: none; // on mobile
  list-style: none;
  border-top: 1px solid lighten($cs-grey, 30%);
}
.c-nav__item {
  border-bottom: 1px solid lighten($cs-grey, 30%);
}
.c-nav__group:last-child .c-nav__item:last-child {
  border-bottom: none;
}
.c-nav__link {
  color: $cs-font;
  padding: 0.5em;
  display: block;
  font-weight: 500;
  text-decoration: none;
  background-color: white;
  @include transition(all 0.5s);
  font-family: "BloggerSans", sans-serif;
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover,
  &:active,
  &:focus {
    color: $cs-font;
    text-decoration: none;
    background-color: #eee;
    @include transition(all 0.3s);
  }
  &.is-active {
    color: lighten($cs-font, 20%);
    &:hover,
    &:active,
    &:focus {
      cursor: default;
      text-decoration: none;
      color: lighten($cs-font, 20%);
    }
  }
}
.c-nav__btn {
  float: right;
  padding: 0.5em;
  color: $cs-font;
  text-decoration: none;
  &:link,
  &:visited {
    color: $cs-font;
  }
  &:hover,
  &:active,
  &:focus {
    color: $cs-font;
  }
}

@media screen and (min-width: 769px) {
  .c-nav {
    float: right;
  }
  .c-nav__group {
    padding-top: 0.5em;
    margin-right: 1em;
    padding-right: 2em;
    display: inline-block;
    border-top: 0.2em solid $cs-a8;
    &:last-child {
      margin-right: 0;
    }
    &:nth-last-of-type(4) {
      border-color: $cs-a6;
    }
    &:nth-last-of-type(3) {
      border-color: $cs-a7;
    }
    &:nth-last-of-type(2) {
      border-color: $cs-a5;
    }
    &:nth-last-of-type(1) {
      border-color: $cs-a3;
    }
  }
  .c-nav__list {
    margin: 0;
    padding: 0;
    display: block; // on desktop
    border-top: none;
  }
  .c-nav__item {
    border-bottom: none;
  }
  .c-nav__link {
    color: $cs-font;
    padding: 0.1em;
    display: inline-block;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      color: $cs-font;
      text-decoration: underline;
      background-color: transparent;
    }
  }
  .c-nav__btn {
    display: none;
  }
}

.lt-ie9 {
  .c-nav {
    float: right;
  }
  .c-nav__group {
    padding-top: 0.5em;
    margin-right: 1em;
    padding-right: 2em;
    display: inline-block;
    border-top: 0.2em solid $cs-grey;
    &:last-child {
      margin-right: 0;
    }
    &:nth-last-of-type(4) {
      border-color: $cs-a2;
    }
    &:nth-last-of-type(3) {
      border-color: $cs-a6;
    }
    &:nth-last-of-type(2) {
      border-color: $cs-a7;
    }
    &:nth-last-of-type(1) {
      border-color: $cs-a5;
    }
  }
  .c-nav__list {
    margin: 0;
    padding: 0;
    display: block; // on desktop
    border-top: none;
  }
  .c-nav__item {
    border-bottom: none;
  }
  .c-nav__link {
    color: $cs-font;
    padding: 0.1em;
    display: inline-block;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      color: $cs-font;
      text-decoration: underline;
      background-color: transparent;
    }
  }
  .c-nav__btn {
    display: none;
  }
}
