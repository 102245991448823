.c-service-listing {
  margin-bottom: 2em;
}

.c-service-listing__image {
  display: block;
}

.c-service-listing {
  &.burgundy {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a2;
    }
  }

  &.orange {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a6;
    }
  }

  &.green {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a7;
    }
  }

  &.blue {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a4;
    }
  }

  .c-media {
    .c-media__body {
      padding: 0 1rem;

      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.8rem;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;
          clear: both;
          display: block;
          font-size: 0.8rem;
          font-weight: bold;

          * {
            color: $cs-font;
          }
        }
      }
    }
  }
}

.c-article-listing__footer {
  padding: 0.6em 0 0.6em 1em;
  @include clearfix;

  a {
    padding-right: 1em;
    font-weight: 500;
    font-family: 'BloggerSans', sans-serif;

    &:link,
    &:visited {
      text-decoration: underline;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  &.cs-a1 {
    a {
      &:link,
      &:visited {
        color: $cs-a1;
      }

      &:hover,
      &:active,
      &:focus {
        color: $cs-a1;
      }
    }
  }
}

.small-print {
  font-size: 0.79rem;
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .flexbox .c-article-listing__image {
    display: none;
  }
}
