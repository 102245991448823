.c-vcard {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.c-vcard__item {
  font-weight: 500;
  font-size: 1.3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  text-decoration: none;
  font-family: 'BloggerSans', sans-serif;
}
.c-vcard__address {
  list-style: none;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.c-vcard__item--header {
  @extend .c-vcard__item;
  font-size: 1em;
}

@media screen and (min-width: 769px) {

  .c-vcard {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .c-vcard__item {
    padding: 0;
  }
  .c-vcard--header {
    display: inline;
  }
  .c-vcard__item--header {
    margin-left: 1em;
    display: inline-block;
  }

}

.lt-ie9 {

  .c-vcard {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .c-vcard__item {
    padding: 0;
  }
  .c-vcard--header {
    display: inline;
  }
  .c-vcard__item--header {
    margin-left: 1em;
    display: inline-block;
  }

}
