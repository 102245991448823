/*------------------------------------*\
    #MAIN
\*------------------------------------*/

/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Configuration and environment settings.
 * Global...............Globally-available variables and settings/config.
 * Colors...............Manage our color palette in isolation.
 *
 * TOOLS
 * Functions............Some simple helper functions.
 * Mixins...............Globally available mixins.
 * Aliases..............Some shorter aliases onto longer variables.
 *
 * GENERIC
 * Normalize.css........A level playing field.
 * Box-sizing...........Better default `box-sizing`.
 * Reset................A pared back reset to remove margins.
 * Shared...............Sensibly and tersely share global commonalities.
 * Lightbox.............Style use for the lightbox plugin.
 *
 * ELEMENTS
 * Page.................Page-level styles (HTML element).
 * Headings.............Heading styles.
 * Links................Hyperlink styles.
 * Lists................Default list styles.
 * Images...............Base image styles.
 * Quotes...............Styling for blockquotes, etc.
 *
 * OBJECTS
 * Wrappers.............Wrappers and page constraints.
 * Layout...............Generic layout module.
 * Headline.............Simple object for structuring heading pairs.
 * Media................The media object.
 * List-inline..........Simple abstraction for setting lists out in a line.
 *
 * COMPONENTS
 * Page header..........Page header styles.
 * Page footer..........Page footer styles.
 * Brand................Make our logo a reusable component.
 * Nav primary..........The site’s main nav.
 * Nav cta..............Secondary nav styles for CTA blocks.
 * Buttons..............Button styles.
 *
 * SCOPES
 * Prose................Set up a new styling context for long-format text.
 *
 * TRUMPS
 * Widths...............Simple width helper classes.
 */

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?y1mkqo');
  src: url('/assets/fonts/icomoon.eot?y1mkqo#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?y1mkqo') format('truetype'),
    url('/assets/fonts/icomoon.woff?y1mkqo') format('woff'),
    url('/assets/fonts/icomoon.svg?y1mkqo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-print:before {
  content: '\e900';
}
.icon-google-plus:before {
  content: '\e901';
}
.icon-facebook:before {
  content: '\e902';
}
.icon-file-text:before {
  content: '\e903';
}

@import 'bower_components/bourbon/app/assets/stylesheets/_bourbon.scss';
@import 'bower_components/neat/app/assets/stylesheets/_neat.scss';
@import 'bower_components/flickity/dist/flickity';

//@import "settings.config";
@import 'settings.global';
@import 'settings.colors';

//@import "tools.functions";
//@import "tools.mixins";
//@import "tools.typography";
//@import "tools.aliases";

@import 'generic.normalize';
@import 'generic.box-sizing';
@import 'generic.lightbox';
@import 'generic.reset';
@import 'generic.shared';

@import 'elements.page';
//@import "elements.headings";
//@import "elements.links";
//@import "elements.lists";
//@import "elements.images";

@import 'objects.wrappers';
@import 'objects.layout';
@import 'objects.sec-nav';
@import 'objects.page-header';
//@import "objects.headline";
//@import "objects.media";
//@import "objects.list-inline";

@import 'component.map';
@import 'component.alert';
@import 'component.media';
@import 'component.brand';
@import 'component.cta-nav';
@import 'component.sec-nav';
@import 'component.hero';
@import 'component.enquiry';
@import 'component.social';
@import 'component.vcard';
@import 'component.cameras';
@import 'component.site-map';
@import 'component.detail';
@import 'component.listings';
@import 'component.site-plan';
@import 'component.pagination';
@import 'component.price-table';
@import 'component.season-table';
@import 'component.price-table-large';
@import 'component.email-signup';
@import 'component.footer-form';
@import 'component.page-footer';
@import 'component.page-header';
@import 'component.nav-primary';
@import 'component.contact-section';
@import 'component.nav-toggle.scss';
@import 'component.overview-section';
@import 'component.article-listing';
@import 'component.service-listing';
@import 'component.facilities';

@import 'scope.prose';

@import 'trumps.widths';

.js-print {
  float: right;
  color: white;
  padding: 0.5em 1em;
  background-color: $cs-a1;
  border: 1px solid darken($cs-a1, 5%);
}
.o-responsive-table {
  overflow-x: auto;
}
.print-content {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

@media print {
  nav,
  .c-map,
  header,
  footer,
  .c-hero,
  .c-enquiry,
  .c-email-signup,
  .c-contact-section,
  .c-sec-nav-section {
    display: none;
  }
  img {
    max-width: 100%;
  }
  .c-price-table {
    padding-top: 2em;
  }
  .print-content {
    display: block;
  }
}
