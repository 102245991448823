.c-facilities {
  float: left;
  clear: both;
  display: block;

  h2 {
    color: $cs-a1;
  }
}

$icon-size: 4.5rem;

.fac-item {
  float: left;
  clear: none;
  display: inline-block;
  margin: 0 0.5% 3rem;
  padding: 0;
  width: 49%;

  .fac-circle {
    width: $icon-size;
    height: $icon-size;
    border-radius: $icon-size * 2;
    margin: 0 auto 1rem;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0;
      padding: 0;
      display: block;
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 2rem;
    }
  }

  h3 {
    font-size: 1.87rem !important;
    line-height: 2.1rem;
    text-align: center;
    width: 96%;
    font-weight: 400;
    margin: 0 2% 0.75rem;
    padding: 0;
    letter-spacing: 0 !important;
    min-height: 4rem;
  }

  .fac-desc {
    color: #fff;
    padding: 1rem;
    min-height: 15rem;
    text-align: center;

    p {
      font-size: 0.78rem;
      line-height: 1rem;
      margin: 0;
      padding: 0;
    }
  }

  &.burgundy {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: rgba(113, 45, 61, 1);
    }

    .fac-circle,
    .fac-desc {
      background-color: rgba(113, 45, 61, 1);
    }
  }

  &.red {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: rgba(164, 32, 53, 1);
    }

    .fac-circle,
    .fac-desc {
      background-color: rgba(164, 32, 53, 1);
    }
  }

  &.orange {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a6;
    }

    .fac-circle,
    .fac-desc {
      background-color: $cs-a6;
    }
  }

  &.green {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a7;
    }

    .fac-circle,
    .fac-desc {
      background-color: $cs-a7;
    }
  }

  &.blue {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $cs-a4;
    }

    .fac-circle,
    .fac-desc {
      background-color: $cs-a4;
    }
  }
}

@media screen and (min-width: 769px) {
  .fac-item {
    margin: 0 0.5% 3rem;
    padding: 0;
    width: 24%;

    .fac-desc {
      min-height: 12rem;
    }
  }
}
