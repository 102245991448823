h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	font-family: 'BloggerSans', sans-serif;
}
img {
	height: auto;
	max-width: 100%;
}

h2 {
	font-size: 1.7em;
}
table {
	width: 100%;
}
.hidden {
	display: none;
}

.c-form {
  width: 100%;
  padding: 1em;
  @include clearfix;
  margin-bottom: 1em;
  border-radius: 0.2em;
  -webkit-appearance: none;
  background-color: white;
  border: 0.1em solid $cs-lgrey;
  input[type], textarea { color: $cs-font; }
  div { margin-bottom: 1em; }
	label { cursor: pointer; display: inline-block; }
	legend { color: $cs-font; }
	fieldset {
	  padding-top: 1em;
	  margin-bottom: 2em;
	  border-radius: 0.2em;
	  border-color: $cs-lgrey;
	  background-color: white;
	}

	input[type="tel"],
	input[type="text"],
	input[type="date"],
	input[type="email"],
	input[type="search"],
	input[type="number"],
	input[type="password"],
	textarea {
	  width: 100%;
	  padding: 0.5em;
	  border: 1px solid $cs-lgrey;
	}
	.help-block { margin-top: 0.5em; margin-bottom: 0.5em; }
	input[type="search"] { @include box-sizing(border-box); }
	input[type="date"] {
	  height: 2.8em;
	  padding-top: 0.5em;
	  padding-bottom: 0.5em;
	  -webkit-appearance: none;
	}
}

// RADIOS & CHECKBOXES
// =============================================================================

input[type="checkbox"] { width: auto; }

.radio, .checkbox { position: relative; }

.radio label, .checkbox label {
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 1.2em;
}
.radio input[type=radio],
.radio--inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox--inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  line-height: normal;
}
input[type=radio],
input[type=checkbox] {
  margin: 0.2em 0 0;
  line-height: normal;
}
input[type=radio][disabled],
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=checkbox].disabled,
fieldset[disabled] input[type=radio],
fieldset[disabled] input[type=checkbox] { cursor: not-allowed; }

.radio--inline + .radio--inline,
.checkbox--inline + .checkbox--inline {
  margin-top: 0;
  margin-left: 0.8em;
}
.radio--inline,
.checkbox--inline {
  cursor: pointer;
  padding-left: 1.3em;
  display: inline-block;
  vertical-align: middle;
}

// SELECT STYLES
// =============================================================================

.form__control {
  width: 100%;
  height: 2.5em;
  display: block;
  padding: 0.5em;
  color: $cs-font;
  font-size: em(14);
  border-radius: 0.3em;
  background-image: none;
  background-color: white;
  border: 0.1em solid $cs-lgrey;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  @include transition(border-color ease-in-out .15s);
}

select[multiple], select[size] { height: auto; }

.c-form__btn {
  color: $cs-font;
  @include transition(all 0.3s);
  padding: 0.5em 0.8em;
  border: 1px solid $cs-font;
  text-transform: uppercase;
  background-color: transparent;
  &:link, &:visited {
    color: $cs-font;
    border: 1px solid $cs-font;
    background-color: transparent;
  }
  &:hover, &:active, &:focus {
    @include transition(all 0.1s);
    color: white;
    border: 1px solid $cs-a1;
    background-color: $cs-a1;
  }
}
