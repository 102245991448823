.c-hero__slide {
  width: 69%;
  opacity: 0.3;
  position: relative;
  &.is-selected {
    opacity: 1;
  }
}
.flickity-prev-next-button {
  display: none;
}
.c-hero__img {
	display: block;
}
.c-hero__link {
  font-weight: 700;
  margin-top: 1.5em;
  padding: 0.3em 1.4em;
  display: inline-block;
  border: 2px solid white;
  &:link, &:visited { color: white; text-decoration: none; }
  &:hover, &:active, &:focus { color: white; text-decoration: none; }
}
.c-hero__textarea-wrap {
  top: 0;
  width: 50%;
  height: 100%;
  display: none;
  position: absolute;
  &.left {
    left: 0;
    text-align: left;
    padding-left: 3em;
    background: linear-gradient(to right, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
  }
  &.right {
    right: 0;
    text-align: right;
    padding-right: 2em;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
  }
  &.center {
    left: 50%;
    margin-left: -25%;
    background: none;
    text-align: center;
  }
}
.c-hero__textarea {
  color: white;
}
.no-flexbox .c-hero__textarea {
  width: 100%;
  bottom: 40%;
  position: absolute;
}
.c-hero__text {
  margin: 0;
  line-height: 1;
  font-size: 4em;
  font-weight: 400;
  font-family: 'BloggerSans', sans-serif;
}

@media (min-width: 769px) {

  .flickity-prev-next-button {
    display: block;
    background-color: $cs-a1;
    &:hover, &:focus {
      background-color: darken($cs-a1, 10%);
    }
  }
  .flickity-prev-next-button .arrow {
    fill: white;
  }
  .flickity-prev-next-button.no-svg {
    color: white;
  }
  .flickity-prev-next-button.previous {
    left: 14.2%;
  }
  .flickity-prev-next-button.next {
    right: 14.2%;
  }

  .c-hero__textarea-wrap {
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
  }
  .no-flexbox .c-hero__textarea-wrap {
    display: block;
  }

}

.lt-ie9 {

  .flickity-prev-next-button {
    display: block;
    background-color: $cs-a1;
    &:hover, &:focus {
      background-color: darken($cs-a1, 10%);
    }
  }
  .flickity-prev-next-button .arrow {
    fill: white;
  }
  .flickity-prev-next-button.no-svg {
    color: white;
  }
  .flickity-prev-next-button.previous {
    left: 14.2%;
  }
  .flickity-prev-next-button.next {
    right: 14.2%;
  }

  .c-hero__textarea-wrap {
    display: block;
  }

}
