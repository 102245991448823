.c-overview-section {
  padding-top: 2em;
  padding-bottom: 2em;
}
.c-overview-section__heading {
  margin-top: 0;
  text-align: center;
}
.c-overview-section__lead {
  font-size: 150%;
  line-height: 1.3;
  font-family: 'BloggerSans', sans-serif;
  &.cs-a1 {
    color: $cs-a1;
  }
  &.cs-a2 {
    color: $cs-a2;
  }
  &.cs-a3 {
    color: $cs-a3;
  }
  &.cs-a4 {
    color: $cs-a4;
  }
  &.cs-a5 {
    color: $cs-a5;
  }
  &.cs-a6 {
    color: $cs-a6;
  }
  &.cs-a7 {
    color: $cs-a7;
  }
  &.cs-a8 {
    color: $cs-a8;
  }
}
.c-overview-section__heading--narrow {
    margin-bottom: 0;
}

@media screen and (min-width: 769px) {

  .c-overview-section {
    @include columns(2);
    p {
      -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                     break-inside: avoid;
    }
  }
  .csscolumns .c-overview-section p:first-of-type { margin-top: 0; }
  .c-overview-section__heading {
    @include column-span(all);
  }

}
