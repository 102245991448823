.screen-reader-text {
  top: -9999px;
  left: -9999px;
  position: absolute;
}

%clearfix {
  &:after {
    content:"";
    clear:both;
    display:table;
  }
}

ul {
  padding-left: 0;
}

a:link, a:visited {
  color: $cs-font;
  text-decoration: none;
}
a:hover, a:active, a:focus {
  color: $cs-font;
  text-decoration: underline;
}

input[type=text],
input[type=email],
input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
