.o-sec-nav {
  list-style: none;
  text-align: center;
}
.o-sec-nav__item {
  margin: 0.5em;
  vertical-align: top;
  display: inline-block;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
