.c-season-table {
  padding: 2em 0;
  background-color: $cs-lgrey;
}

.c-season-table__price {
  width: 4em;
  height: 4em;
  font-weight: 400;
  font-size: 14px;
  color: white;
  background-color: $cs-a6;
  @include display(flex);
  @include flex-direction(column);
  @include justify-content(center);
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
}

tr:nth-of-type(odd) {
  .c-season-table__price {
    color: $cs-a6;
    background-color: #fff;
  }
}
.c-season-table__heading {
  color: $cs-a6;
  font-weight: 300;
}
.c-season-table__item {
  font-family: 'BloggerSans', sans-serif;

  thead {
    tr {
      border-bottom: 1px dotted darken($cs-lgrey, 10%);

      th {
        padding: 1em 0;
        text-align: left;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.35rem;
        width: 16.875% !important;

        span {
          clear: both;
          display: block;
          width: 100%;
          font-size: 10px;
        }

        &:first-of-type {
          width: 25% !important;
          padding-right: 5% !important;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px dotted darken($cs-lgrey, 10%);
      text-align: left;

      td {
        padding: 1em;
        font-weight: 400;
      }

      th {
        &.c-season-table__row-heading {
          width: 25% !important;
          padding-right: 2.5% !important;

          p {
            margin: 0;

            &:first-of-type {
              font-weight: 500;
              font-weight: 1.2rem;
              font-size: 1.2rem;
            }

            &:last-of-type {
              font-weight: 100 !important;
              font-size: 1rem;
              line-height: 1.1rem;
              width: 75%;
            }
          }
        }
      }
    }
  }
}

.c-season-table {
  table {
    tbody {
      overflow: visible;

      tr {
        overflow: visible;

        &:last-of-type {
          border-bottom: 0 !important;

          td {
            &:last-of-type {
              padding: 2rem;

              p {
                position: relative;
                top: 1.55rem;
                font-size: 0.9rem;

                &:first-of-type {
                  font-weight: bold;
                }

                &:last-of-type {
                  font-weight: 300 !important;
                  font-size: 0.67rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.c-season-table__row-info {
  p {
    margin: 0;
  }
}

@media screen and (min-width: 769px) {
  .c-season-table__price {
    font-size: 20px;
  }
}
