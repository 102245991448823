.c-page-footer {
  padding-top: 1em;
  padding-bottom: 2em;
}

.c-page-footer__small {
  display: block;
  margin-top: 2em;
  text-align: center;
}

.c-page-footer__heading {
  line-height: 1.1;
  margin-bottom: 0.2em;
}

.c-page-footer__logos {
  img {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    padding: 0;
    width: 100%;
    max-width: 4.5rem;
    height: auto;
    vertical-align: middle;
  }
}

.c-page-footer__text {
  margin-top: 0.3em;
}

@media (min-width: 769px) {
  .c-page-footer__small {
    text-align: right;
  }
}

.lt-ie9 {
  .c-page-footer__small {
    text-align: right;
  }
}
