.c-site-plan {
    padding-top: 1em;
    padding-bottom: 4em;
}

.c-site-plan__heading {
    margin-bottom: 0;
    &.cs1 {
        color: $cs-a1;
    }
    &.cs2 {
        color: $cs-a2;
    }
    &.cs2 {
        color: $cs-a3;
    }
    &.cs3 {
        color: $cs-a4;
    }
    &.cs4 {
        color: $cs-a4;
    }
    &.cs5 {
        color: $cs-a5;
    }
    &.cs6 {
        color: $cs-a6;
    }
    &.cs-a7 {
      color: $cs-a7;
    }
    &.cs-a8 {
      color: $cs-a8;
    }
}
