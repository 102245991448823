.c-article-listing {
  margin-bottom: 2em;
}
.c-article-listing__image {
  display: block;
}
.c-article-listing__footer {
  padding: 0.6em 0 0.6em 1em;
  @include clearfix;
  a {
    padding-right: 1em;
    font-weight: 500;
  	font-family: 'BloggerSans', sans-serif;
    &:link, &:visited { text-decoration: underline; }
    &:hover, &:active, &:focus { text-decoration: none; }
  }
  &.cs-a1 {
    a {
      &:link, &:visited { color: $cs-a1; }
      &:hover, &:active, &:focus { color: $cs-a1; }
    }
  }
}
@media screen and (min-width: 769px) {

  .flexbox .c-article-listing__image {
    display: none;
  }

}
