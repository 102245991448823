.c-cameras {
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
}

.c-cameras__item {
    margin-bottom: 2em;
}
@media screen and (min-width: 769px) {

    .c-cameras__item {
        max-width: 48%;
        margin-right: 2%;
        vertical-align: top;
        display: inline-block;
        &:last-child {
            margin-right: 0;
        }
    }

}
