.c-page-header {
  @include clearfix;
  padding-top: 1em;
}

@media screen and (min-width: 769px) {

  .c-page-header {
    padding-bottom: 1em;
  }

}

.lt-ie9 {

  .c-page-header {
    padding-bottom: 1em;
  }

}
