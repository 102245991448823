.c-email-signup {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: $cs-grey;
}

.c-email-signup__control {
  margin-bottom: 1em;
}

.c-email-signup__heading {
  color: white;
  margin-top: 0;
  line-height: 2;
  margin-bottom: 0;
}

.c-email-signup__label {
  top: -9999px !important;
  left: -9999px !important;
  position: absolute !important;
}

.c-email-signup__input {
  width: 100%;
  color: white;
  padding: 0.5em;
  display: inline-block;
  background-color: $cs-grey;
  border: 0.1em solid white;
}

.c-email-signup__btn {
  color: white;
  border: none;
  line-height: 2;
  background-color: transparent;
}

.c-email-signup ::-webkit-input-placeholder {
   color: white;
}

.c-email-signup :-moz-placeholder { /* Firefox 18- */
   color: white;
}

.c-email-signup ::-moz-placeholder {  /* Firefox 19+ */
   color: white;
}

.c-email-signup :-ms-input-placeholder {
   color: white;
}

.c-email-signup :placeholder {
  color: white;
}

@media screen and (min-width: 769px) {

  .c-email-signup__control {
    width: 28%;
    float: left;
    margin-right: 1%;
    margin-bottom: 0;
    display: inline-block;
  }

  .c-email-signup__heading {
    float: left;
    margin-right: 1%;
    display: inline-block;
  }

}

@media screen and (min-width: 885px) {

  .c-email-signup__control {
    width: 31%;
  }

}

@media screen and (min-width: 1085px) {

  .c-email-signup__control {
    width: 33%;
  }

}

.lt-ie9 {

  .c-email-signup__control {
    width: 33%;
    float: left;
    margin-right: 1%;
    margin-bottom: 0;
    display: inline-block;
  }

  .c-email-signup__heading {
    float: left;
    margin-right: 1%;
    display: inline-block;
  }

}
