.c-price-table {
  width: 100%;
  padding: 0 1em;
  background-color: $cs-lgrey;
}
.c-price-table__heading {
  width: 20%;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
  color: darken($cs-font, 10%);
  font-family: BloggerSans, sans-serif;
}
.c-price-table__data,
.c-price-table__heading {
  padding: 0.8em 0;
}
.c-price-table__row {
  border-bottom: 1px dotted darken($cs-lgrey, 10%);
  &:last-child {
    border-bottom: none;
  }
}
