$cs-a1: rgb(100, 152, 173); // BRAND BLUE
$cs-a2: rgb(132, 26, 41); // DARK RED
$cs-a3: rgb(103, 39, 54); // DARKER RED
$cs-a4: rgb(60, 105, 131); // DARK BLUE
$cs-a5: rgb(59, 152, 158); // TURQUOISE
$cs-a6: rgb(234, 160, 49); // YELLOW
$cs-a7: rgb(149, 156, 57); // GREEN
$cs-a8: rgb(204, 89, 41); // ORANGE

$cs-font: rgb(112, 111, 111);
$cs-grey: rgb(157, 157, 156);
$cs-lgrey: rgb(238, 238, 238);
