.c-price-table-large {
  padding-top: 1em;
  font-weight: 500;
  font-style: normal;
  padding-bottom: 2em;
  font-family: 'BloggerSans';
  background-color: $cs-lgrey;
}

.c-price-table-large__heading {
  line-height: 1.1;
}

.c-price-table-large {
  table {
    width: 100%;
    max-width: 100%;
  }
  th {
    text-align: left;
  }
  th, td {
    width: 25%;
    padding: 0.7em;
    font-size: 1.2em;
  }
  tr {
    border-bottom: 1px dotted darken($cs-lgrey, 10%);
  }
  tfoot {
    tr:last-child {
      border-bottom: none;
    }
  }
}
.c-price-table-large__table {
  min-height: .01%;
  overflow-x: auto;
}

.c-price-table-large__price {
  width: 80px;
  height: 80px;
  font-weight: bold;
	font-style: normal;
  text-align: center;
  border-radius: 50%;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  font-family: sans-serif;
}

// COLOUR OPTIONS
.c-price-table-large.cs-a1 {

  .c-price-table-large__heading {
    color: $cs-a1;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a1;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a1;
  }

}

.c-price-table-large.cs-a2 {

  .c-price-table-large__heading {
    color: $cs-a2;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a2;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a2;
  }

}

.c-price-table-large.cs-a3 {

  .c-price-table-large__heading {
    color: $cs-a3;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a3;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a3;
  }

}

.c-price-table-large.cs-a4 {

  .c-price-table-large__heading {
    color: $cs-a4;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a4;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a4;
  }

}

.c-price-table-large.cs-a5 {

  .c-price-table-large__heading {
    color: $cs-a5;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a5;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a5;
  }

}

.c-price-table-large.cs-a6 {

  .c-price-table-large__heading {
    color: $cs-a6;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a6;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a6;
  }

}

.c-price-table-large.cs-a7 {

  .c-price-table-large__heading {
    color: $cs-a7;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a7;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a7;
  }

}

.c-price-table-large.cs-a8 {

  .c-price-table-large__heading {
    color: $cs-a8;
  }
  tbody tr:nth-of-type(odd) .c-price-table-large__price {
    color: $cs-a8;
  }
  tbody tr:nth-of-type(even) .c-price-table-large__price {
    color: white;
    background-color: $cs-a8;
  }

}

@media screen and (min-width: 769px) {

  .c-price-table-large {
    table {
      table-layout: fixed;
    }
  }

}
