.s-prose {

  blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1em;
    border-left: 0.2em solid $cs-a1;
  }

  a:link, a:visited { color: $cs-font; text-decoration: none; }
  a:hover, a:active, a:focus { color: $cs-font; text-decoration: underline; }

  &.cs-a1 {
    a:link, a:visited { color: $cs-a1; }
    a:hover, a:active, a:focus { color: $cs-a1; }
  }
  &.cs-a2 {
    a:link, a:visited { color: $cs-a2; }
    a:hover, a:active, a:focus { color: $cs-a2; }
  }
  &.cs-a3 {
    a:link, a:visited { color: $cs-a3; }
    a:hover, a:active, a:focus { color: $cs-a3; }
  }
  &.cs-a4 {
    a:link, a:visited { color: $cs-a4; }
    a:hover, a:active, a:focus { color: $cs-a4; }
  }
  &.cs-a5 {
    a:link, a:visited { color: $cs-a5; }
    a:hover, a:active, a:focus { color: $cs-a5; }
  }
  &.cs-a6 {
    a:link, a:visited { color: $cs-a6; }
    a:hover, a:active, a:focus { color: $cs-a6; }
  }
  &.cs-a7 {
    a:link, a:visited { color: $cs-a7; }
    a:hover, a:active, a:focus { color: $cs-a7; }
  }
  &.cs-a8 {
    a:link, a:visited { color: $cs-a8; }
    a:hover, a:active, a:focus { color: $cs-a8; }
  }

}
