.c-media {
  @include clearfix;
  @include display(flex);
  @include flex-direction(column);
}
.c-media__body {
  background-color: $cs-lgrey;
}
.c-media__image {
  background-image: none;
}
.c-media__body-pad {
  padding: 1em;
}

@media screen and (min-width: 769px) {

  .c-media {
    @include flex-direction(row);
  }
  .c-media__body {
    width: 50%;
  }
  .c-media__image {
    width: 50%;
    height: auto;
    background-size: cover;
  }

  .no-flexbox {
    .c-media__body {
      width: 50%;
      float: left;
    }
    .c-media__image {
      width: 50%;
      float: right;
    }
  }

}
