html {
	font-size: 100%;
	color: $cs-font;
	line-height: 1.5;
  font-family: arial, sans-serif;
}
body {
	font-size: 1em;
}

@media screen and (min-width: 769px) {

  body {
    animation: fadeIn 2s ease;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
