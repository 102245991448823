.c-brand {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  text-align: center;
}

.c-brand__link {
  color: black;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: black;
  }
}

@media screen and (min-width: 769px) {
  .c-brand {
    float: left;
    text-align: left;
    margin-top: 1.3em;
    margin-bottom: 0;
    max-width: 17.5rem;
  }
}

.lt-ie9 {
  .c-brand {
    float: left;
    text-align: left;
    margin-top: 1.3em;
    margin-bottom: 0;
  }
}
