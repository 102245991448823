.c-cta-nav {
  @include display(flex);
  @include flex-direction(column);
  @include justify-content(top);
  color: white;
  width: 13em;
  height: 13em;
  text-align: center;
  border-radius: 50%;
  text-decoration: none;
  padding: 2.8em 1em 1em;
  @include transition(all, 0.3s);
  &:link,
  &:visited {
    text-decoration: none;
    background-color: $cs-a1;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    @include transition(all, 0.1s);
    background-color: darken($cs-a1, 10%);
  }
}

.o-sec-nav__item:nth-of-type(4) {
  .c-cta-nav {
    &:link,
    &:visited {
      background-color: $cs-a7;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: darken($cs-a7, 10%);
    }
  }
}
.o-sec-nav__item:nth-of-type(3) {
  .c-cta-nav {
    &:link,
    &:visited {
      background-color: $cs-a6;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: darken($cs-a6, 10%);
    }
  }
}
.o-sec-nav__item:nth-of-type(2) {
  .c-cta-nav {
    &:link,
    &:visited {
      background-color: $cs-a1;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: darken($cs-a1, 10%);
    }
  }
}
.o-sec-nav__item:nth-of-type(1) {
  .c-cta-nav {
    &:link,
    &:visited {
      background-color: $cs-a8;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: darken($cs-a8, 10%);
    }
  }
}

.c-cta-nav__heading {
  color: white;
  font-size: 1.4em;
  margin: 0.5em 0 0;
}
.c-cta-nav__text {
  margin: 0;
  color: white;
  font-size: 0.9em;
  line-height: 1.4;
}
.c-cta-nav__img {
  height: 30px;
  margin: 0 auto;
}
.no-flexbox .c-cta-nav {
  display: table-cell;
  vertical-align: middle;
}
