@media screen and (min-width: 568px) {

  .cols-6 {
      @include span-columns(6);
  }

}
@media screen and (min-width: 769px) {

  .cols-3 {
    @include span-columns(3);
  }
  .cols-9 {
    @include span-columns(9);
  }

}

.lt-ie9 {

  .cols-3 {
    @include span-columns(3);
  }
  .cols-6 {
      @include span-columns(6);
  }
  .cols-9 {
    @include span-columns(9);
  }

}
