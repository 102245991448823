.c-alert {
  padding: 1em;
  border-radius: 0.2em;
  background-color: #f5f5f5;
  color: darken(#f5f5f5, 30%);
  border: 0.1em solid darken(#f5f5f5, 10%);
}

.c-alert--warning {
  background-color: #ffe0b2;
  color: darken(#ffe0b2, 30%);
  border: 0.1em solid darken(#ffe0b2, 10%);
}

.c-alert--danger {
  color: white;
  background-color: $cs-a1;
  border: 0.1em solid darken($cs-a1, 10%);
}
