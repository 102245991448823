.c-nav-toggle { cursor: pointer; padding: 10px 35px 16px 0px; }
.c-nav-toggle i, .c-nav-toggle i:before, .c-nav-toggle i:after {
  height: 5px;
  width: 30px;
  content: '';
  cursor: pointer;
  position: absolute;
  border-radius: 0.1em;
  display: inline-block;
  background-color: $cs-grey;
}
.c-nav-toggle i:before {
  top: -10px;
}
.c-nav-toggle i:after {
  bottom: -10px;
}

.c-nav-toggle i,
.c-nav-toggle i:before,
.c-nav-toggle i:after {
  @include transition(all 500ms ease-in-out);
}
.c-nav-toggle.is-active i {
  background-color: transparent;
}
.c-nav-toggle.is-active i:before,
.c-nav-toggle.is-active i:after {
  top: 0;
}
.c-nav-toggle.is-active i:before {
  @include transform(rotate(45deg));
}
.c-nav-toggle.is-active i:after {
  @include transform(rotate(-45deg));
}
